/* eslint-disable react/style-prop-object */
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { Button, Capsule } from 'components';
import { POUCHERS_APP_URL } from 'constants';
import DollarCard from 'assets/images/card.png';
import NewDashboard from 'assets/images/new.svg';
import Balance2 from 'assets/images/balance_2.svg';
import Balance3 from 'assets/images/balance_3.svg';
import AppStore from 'assets/svg/app-store-icon-white.svg';
import PlayStore from 'assets/svg/play-store-icon-white.svg';
import { ReactComponent as Arrow } from 'assets/svg/arrow-down.svg';

export default function Hero() {
  const [ref] = useInView({ triggerOnce: true, threshold: 0.5 });

  return (
    <div
      className="container flex flex-col lg:flex-row items-center lg:justify-between pt-[120px] gap-7 md:gap-2 md:pt-[140px] xl:pt-[170px] xl:pb-[120px] pb-[50px] md:pb-[70px]"
      ref={ref}>
      <div className="max-w-[800px] lg:max-w-[592px] text-left lg:mr-[59px] mt-[17px] items-center md:items-start md:mt-[60px] lg:mt-0 flex flex-col gap-[32px] md:gap-[48px]">
        <div className="flex flex-col items-center md:items-start text-center md:text-left gap-[24px]">
          <Capsule className="capitalize font-dmsans_r w-fit">
            No. 1 Virtual USD Card Provider in Nigeria
          </Capsule>
          <h1 className="text-white text-36 sm:text-56 font-dmsans_b w-full leading-[44px] sm:leading-[64px]">
            Spend, Send and Pay: Anywhere, Anytime.
          </h1>
          <div className="max-w-[359px]">
            <p className="text-16 text-white font-dmsans_r leading-[24px]">
              Enjoy borderless payments with our virtual dollar card, send money in seconds, pay
              bills and lots more.
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-[16px]">
          <Link
            to={`${POUCHERS_APP_URL}/create-account`}
            target="_blank"
            className="bg-secondary_purple rounded-lg flex items-center justify-center text-white font-inter_medium h-[44px] w-[126px] xl:h-[60px] xl:w-[181px] text-14 xl:text-18 whitespace-nowrap">
            Get Started
          </Link>
          <div className="w-full flex justify-start mb-[20px]">
            <div className="flex items-center justify-between gap-2">
              <p className="text-12 md:text-14 xl:text-20 font-inter_medium xl:font-inter_semiBold text-white">
                Available on
              </p>
              <Link to={`${process.env.REACT_APP_POUCHERS_APP_IOS}`} target="_blank">
                <img
                  alt="app store"
                  src={AppStore}
                  className="w-[14.28px] md:w-[40.62px] h-[14.28px] md:h-[40.62px]"
                />
              </Link>
              <Link to={`${process.env.REACT_APP_POUCHERS_APP_ANDROID}`} target="_blank">
                <img
                  alt="play store"
                  src={PlayStore}
                  className="w-[14.28px] md:w-[40.62px] h-[14.28px] md:h-[40.62px]"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:pt-0 max-w-[595px] h-auto max-h-[347.25px] md:max-h-[542.38px] overflow-hidden relative flex items-center justify-center">
        <motion.img
          initial={{ opacity: 0, translateY: -100 }}
          animate={{ opacity: 100, translateY: 0, transition: { delay: 0.7, duration: 0.8 } }}
          alt=""
          src={NewDashboard}
          className="w-4/5 max-w-[268.15px] h-auto max-h-[347.25px] md:max-h-[542.38px] object-contain"
        />
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 100, transition: { delay: 1.5, duration: 0.8 } }}
          alt="wallet balance"
          src={Balance2}
          className="w-4/5 max-w-[147.25px] md:max-w-[230px] h-auto max-h-[44.82px] lg:max-h-[70px] object-contain absolute left-[2%] md:left-[2%] lg:left-0 xl:left-[2%] bottom-[51%] md:bottom-[57%] -translate-y-1/2"
        />
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 100, transition: { delay: 1.5, duration: 0.8 } }}
          alt="wallet balance"
          src={Balance3}
          className="w-4/5 max-w-[147.25px] md:max-w-[230px] h-auto max-h-[44.82px] lg:max-h-[70px] object-contain absolute left-[2%] md:left-[2%] lg:left-0 xl:left-[2%] bottom-[15%] md:bottom-[20%] -translate-y-1/2"
        />
        <motion.img
          initial={{ opacity: 100, translateX: '100%' }}
          animate={{ opacity: 100, translateX: 0, transition: { delay: 1.3, duration: 0.8 } }}
          alt="mastercard"
          src={DollarCard}
          className="w-4/5 max-w-[183.64px] md:max-w-[286.83px] h-auto max-h-[170px] md:max-h-[295px] object-contain absolute right-[1%] lg:-right-[10%] xl:right-[1%] bottom-[14%]"
        />
      </div>
    </div>
  );
}
