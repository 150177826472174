import React, { useEffect, useState } from 'react';
import GlobeIcon from '../../assets/svg/duo-icons_world.svg';
import SendIcon from '../../assets/svg/lets-icons_send-duotone.svg';
import SolarCardIcon from '../../assets/svg/solar_card-bold-duotone.svg';

const Carousel = () => {
  const slides = [
    {
      image: SendIcon,
      text: 'Send money instantly to friends and family in 7+ countries with zero hassle, ensuring fast and secure transactions worldwide.',
      title: 'Seamless money transfers anytime'
    },
    {
      image: SolarCardIcon,
      text: 'With our virtual USD card, you have the world at your fingertips, shop, pay bills and subscriptions on thousands of websites around the world.',
      title: 'Pay for Subscriptions, Shop Anywhere in the world'
    },
    {
      image: GlobeIcon,
      text: 'Receive payments worldwide in your preferred currency with ease, making international transactions simple, fast, and hassle-free. ',
      title: 'Effortless global payment collection'
    }
  ];

  return (
    <div className="bg-carousel py-[25px] md:pt-[96px] md:pb-[150px]">
      <div className="container flex flex-col md:items-center lg:justify-between gap-[30px] md:gap-[56px]">
        <h2 className="md:capitalize text-[24px] leading-[32px] lg:text-[36px] lg:leading-[48px] font-dmsans_b max-w-[718px] md:text-center">
          All your money needs in one app
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-[30px] lg:gap-[10px] xl:gap-[30px] w-full relative">
          <div className="hidden lg:block absolute w-4/5 h-[95%] bg-gradient-to-br from-[#59CDFF30] to-[#D458FF30] rounded-[30px] -bottom-[10%] left-[10%]"></div>
          {slides?.map(({ image, text, title }, index) => (
            <div
              key={title}
              className="flex flex-col justify-between gap-[16px] bg-white rounded-[24px] py-[40px] px-[30px] md:py-[24px] lg:py-[16px] md:px-[20px] xl:px-[32px] z-10">
              <div className="h-[48px] w-[48px] md:w-[72px] md:h-[72px] rounded-full flex items-center justify-center bg-carousel">
                <img alt={title} src={image} className='h-[27px] w-[27px] md:h-[40px] md:w-[40px]' />
              </div>
              <div className="flex flex-col mt-[16px] md:mt-[32px] gap-[12px] md:gap-[16px] flex-1">
                <h5 className="text-[24px] lg:text-[20px] xl:text-[24px] font-dmsans_m text-dark_blue">{title}</h5>
                <p className="font-dmsans_r text-[18px] md:text-[20px] text-grey">{text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
