import React, { useLayoutEffect, useState } from 'react';
import { ReactComponent as AppLogo } from 'assets/svg/app-logo.svg';
import { Button, Hamburger } from 'components';
import { Link } from 'react-router-dom';
import { POUCHERS_APP_URL, POUCHERS_WEBSITE_URL } from 'constants';
import MobileMenu from './MobileMenu';
import useWindowWidth from 'hooks/useWindowWidth';

export default function Header() {
  const width = useWindowWidth()
  const [showSlider, setShowSlider] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);
  const [ProductListComponent, setProductListComponent] = useState(null);
  const [FeatureListComponent, setFeatureListComponent] = useState(null);

  useLayoutEffect(() => {
    const loadComponent = async () => {
      if (width > 640) {
        const ProductList = (await import('./ProductList')).default;
        const FeatureList = (await import('./FeatureList')).default;
        setProductListComponent(() => ProductList);
        setFeatureListComponent(() => FeatureList);
      }
    };
    loadComponent();
  }, [width]);

  return (
    <div className='bg-primary py-[10px] md:py-[20px] fixed w-full top-0 left-0 z-20 bg-purple'>
      <div className='flex justify-between items-center container'>
        <div className="stagger__in--2">
          <Link to="/" className='hide_tap'>
            <AppLogo className="w-[130px] sm:w-[167px] h-[40px]" />
          </Link>
        </div>
        {width > 640 ?
          <div className='lg:flex items-center hidden text-white text-16 font-dmsans_m'>
            {ProductListComponent ? <ProductListComponent
              setShowProducts={setShowProducts}
              showProducts={showProducts}
              setShowFeatures={setShowFeatures}
            /> : ''}
            {FeatureListComponent ? <FeatureListComponent
              setShowFeatures={setShowFeatures}
              showFeatures={showFeatures}
              setShowProducts={setShowProducts}
            /> : ''}
            <a href={`${POUCHERS_WEBSITE_URL}/blog`} className='mr-[32px] mb-[20px] sm:mb-0 hide_tap'>Blog</a>
          </div> : <></>
        }
        <div className='lg:flex items-center hidden'>
          <a href={`${POUCHERS_APP_URL}`} target="_blank" rel="noreferrer" >
            <Button className="bg-secondary-text text-purple h-full whitespace-nowrap font-dmsans_m mr-[16px]">
              Sign Up
            </Button>
          </a>
          <a href={`${POUCHERS_APP_URL}`} target="_blank" rel="noreferrer" >
            <Button className="text-white font-dmsans_m">Login</Button>
          </a>
        </div>
        <div
          className="lg:hidden"
          onClick={() => setShowSlider((prev) => !prev)}
        >
          <Hamburger className="stagger__in--3" active={showSlider} />
        </div>
      </div>
      <MobileMenu showSlider={showSlider} setShowSlider={setShowSlider} />
    </div>
  );
};