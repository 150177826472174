export const products = [
  {
    id: 1,
    name: 'airtime',
    url: 'airtime'
  },
  {
    id: 2,
    name: 'data',
    url: 'data'
  },
  {
    id: 3,
    name: 'cable TV',
    url: 'cable'
  },
  {
    id: 4,
    name: 'electricity',
    url: 'electricity'
  },
  {
    id: 5,
    name: 'internet',
    url: 'internet'
  },
  {
    id: 6,
    name: 'betting',
    url: 'betting'
  },
  // {
  //   id: 7,
  //   name: 'naira card',
  //   url: 'naira-card'
  // },
  {
    id: 8,
    name: 'dollar card',
    url: 'dollar-card'
  }
]

export const features = [
  // {
  //   id: 1,
  //   name: 'Virtual Cards',
  //   url: 'virtual-card'
  // },
  {
    id: 2,
    name: 'Bill Scheduling and Automation',
    url: 'bill-scheduling-and-automation'
  },
  {
    id: 3,
    name: 'Spending Analytics',
    url: 'spending-analytics'
  },
  {
    id: 4,
    name: 'Digital Wallet',
    url: 'digital-wallet'
  },
  {
    id: 5,
    name: 'Money Transfer',
    url: 'money-transfer'
  },
  // {
  //   id: 6,
  //   name: 'Referrals',
  //   url: 'referrals'
  // }
]