import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function ReadMore({ url }) {
  return (
    <Link
      to={url}
      className="w-[123px] h-[48px] rounded-lg bg-blue text-white border border-blue text-16 leading-[24px] font-dmsans_m flex items-center justify-center">
      Read More
    </Link>
  );
}

ReadMore.propTypes = {
  url: PropTypes.string
};
