import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ReadMore from './ReadMore';

export default function Service({
  title,
  showBtn = true,
  description,
  image,
  src,
  textLeft,
  icon,
  onClick,
  srcSet,
  url
}) {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });

  const variants = {
    visible: { x: '0px', opacity: 1, transition: { type: 'spring', duration: 5 } },
    hidden: { x: textLeft ? '100px' : '-100px', opacity: 0 }
  };

  return (
    <div className="container py-[32px] lg:py-[64px]">
      <div className="flex flex-col lg:flex-row items-center justify-between">
        <motion.div
          ref={ref}
          variants={variants}
          animate={inView ? 'visible' : 'hidden'}
          initial="hidden"
          className={`'basis-[50%]' ${textLeft ? '' : 'lg:mr-[40px]'}`}>
          {src ? (
            <picture>
              <source srcSet={srcSet} type="image/webp" />
              <img src={src} width={576} height={592} alt={title} />
            </picture>
          ) : (
            image
          )}
        </motion.div>
        <div
          className={`basis-[50%] max-w-[576px] mb-[40px] lg:mb-0 ${
            textLeft ? 'order-first lg:mr-[40px]' : 'order-first lg:order-last'
          }`}>
          {<div className="mb-6">{icon}</div> || (
            <p className="text-purple text-16 font-dmsans_b mb-[12px]">Features</p>
          )}
          <h3
            className={`text-32 sm:text-36 font-dmsans_b leading-[48px] ${
              showBtn ? 'lg:mb-[40px] mb-[20px]' : 'mb-4'
            } `}>
            {title}
          </h3>
          <p className="text-18 font-dmsans_r leading-[32px] text-[#667085] mb-[40px]">
            {description}
          </p>
          {showBtn && (
            <Button
              onClick={onClick}
              className="px-[20px] py-[12px] border-[#D0D5DD] border text-[#344054] text-16 font-inter font-medium">
              Read More
            </Button>
          )}
          {url && <ReadMore url={url} />}
        </div>
      </div>
    </div>
  );
}

Service.propTypes = {
  title: PropTypes.any,
  showBtn: PropTypes.any,
  description: PropTypes.any,
  image: PropTypes.any,
  src: PropTypes.any,
  textLeft: PropTypes.any,
  icon: PropTypes.any,
  onClick: PropTypes.any,
  srcSet: PropTypes.any,
  url: PropTypes.string
};
