import React from 'react';
import { Link } from 'react-router-dom';

import Audience from '../../assets/images/audience.png';
import { POUCHERS_APP_URL } from 'constants';

const Target = () => {
  return (
    <div className="bg-[#0E0E40]">
      <div className="container flex flex-col items-center lg:justify-center gap-[64px] pt-[30px] pb-[50px] md:pt-[96px] md:pb-[30px]">
        <div className="flex flex-col items-center text-center gap-[24px] lg:gap-[48px] w-full max-w-[896px] mx-auto">
          <div className="flex flex-col items-center text-center gap-[16px] lg:px-[20px] xl:px-[30px]">
            <h3 className="text-[36px] leading-[44px] md:text-[44px] lg:text-[56px] lg:leading-[64px] text-white font-dmsans_b">
              Ready to say bye to bills & hi to freedom?
            </h3>
            <p className="text-[18px] leading-[28px] lg:text-[24px] lg:leading-[31.25px] text-[#BDBDBD] font-dmsans_r lg:w-4/5 mx-auto">
              Take charge with Pouchers—automate bills, track spending, and get virtual cards. It’s
              your all-in-one financial sidekick!
            </p>
          </div>
          <Link
            to={`${POUCHERS_APP_URL}`} 
            target="_blank"
            className="w-[202.24px] py-[10px] md:py-[16px] font-dmsans_m text-[15.93px] leading-[19.12px] flex items-center justify-center rounded-[8px] lg:w-[254px] lg:h-[56px] text-white lg:text-[20px] lg:leading-[24px] bg-secondary_purple">
            Create a free account
          </Link>
        </div>
        <div className="w-full h-[170.77px] md:h-[500px] lg:h-[700px] relative overflow-hidden">
          <img alt="" src={Audience} />
        </div>
      </div>
    </div>
  );
};

export default Target;
