import React, { useEffect, useState } from 'react';
import { ReactComponent as Schedule } from 'assets/svg/schedule.svg';
import { ReactComponent as Analytics } from 'assets/svg/analytics.svg';

import SendMoney from 'assets/images/send-money.png';
import SendMoney600 from 'assets/images/send-money600.webp';
import { VirtualCard, Hero, Service, Carousel, Location } from 'containers/Home';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { POUCHERS_WEBSITE_URL, POUCHERS_LOGO_URL } from 'constants';

const description =
  'Pouchers is the best bill payment platform to pay bills like utilities, buy airtime, send money, schedule bills and shop around the world with virtual dollar cards all in one place.';
const title = 'Pouchers: Pay Bills, Buy Airtime, Shop with Virtual Cards';
const image = POUCHERS_LOGO_URL;
const url = `${POUCHERS_WEBSITE_URL}`;

export default function Home() {
  const navigate = useNavigate();

  const [dynamicDescription, setDynamicDescription] = useState(description);
  const [dynamicTitle, setDynamicTitle] = useState(title);

  useEffect(() => {
    setDynamicDescription(description);
    setDynamicTitle(title);
  }, []);

  return (
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
        <meta name="description" content={dynamicDescription} />

        <meta itemProp="name" content={dynamicTitle} />
        <meta itemProp="description" content={dynamicDescription ?? 'describe-description'} />
        <meta itemProp="image" content="" />

        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={dynamicTitle ?? 'hey-social'} />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={dynamicTitle ?? 'hey-social'} />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />
        <meta name="title" content={dynamicTitle} />
        <meta name="description" content={dynamicDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={dynamicTitle} />
        <meta property="og:description" content={dynamicDescription} />
        <meta property="og:image" content={image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={url} />
        <meta name="twitter:title" content={dynamicTitle} />
        <meta name="twitter:description" content={dynamicDescription} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <div className="bg-purple">
        <Hero />
      </div>
      <Carousel />
      <Location />
      <div className="bg-white">
        <VirtualCard />
        <Service
          title="Schedule & Automate Bills "
          description={`Skip the annoying manual bill payment and let Pouchers automate your payments. With this feature, you can schedule your recurring bills to be paid daily, weekly, monthly or quarterly. `}
          image={<Schedule />}
          onClick={() => navigate('bill-scheduling-and-automation')}
          textLeft={true}
          showBtn={false}
          url="bill-scheduling-and-automation"
        />
        <Service
          title="Spending Analytics"
          description={`Want to know where your money is going? Pouchers Spending Analytics helps you review your income and expenses over a specific period. With this info, you can make better financial decisions and plans.`}
          image={<Analytics />}
          onClick={() => navigate('spending-analytics')}
          textLeft={false}
          showBtn={false}
          url="spending-analytics"
        />
        <Service
          title="Send Money Instantly Using User Tags"
          description={`Send and receive money with ease using Pouchers User Tags. No more long account details—just simple, free transfers to friends and family!`}
          src={SendMoney}
          srcSet={`${SendMoney600} 600w, ${SendMoney} 1600w`}
          onClick={() => navigate('money-transfer')}
          textLeft={true}
          showBtn={false}
          url="spending-analytics"
        />
      </div>
    </>
  );
}
