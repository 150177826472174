import React from 'react';
import Content from 'assets/svg/Content.svg';
import ReadMore from './ReadMore';


export default function VirtualCard() {




  return (
    <div className="container pt-[60px] lg:py-[64px]">
      <div className="flex flex-col gap-[48px] lg:gap-[64px] lg:flex-row-reverse items-center justify-between">
        <div className="basis-[50%]">
          <img alt="cards" src={Content} />
        </div>
        <div className="basis-[50%] max-w-[576px] order-first flex flex-col gap-[20px] lg:gap-[40px] mb-[40px] lg:mb-0">
          <h3 className="text-32 sm:text-36 font-dmsans_b leading-[40px] sm:leading-[48px]">
            Get A Virtual Dollar Card.
          </h3>
          <p className="text-18 font-dmsans_r leading-[32px] text-[#667085]">
            Say goodbye to declined payments with Pouchers Virtual Card. From Amazon shopping to
            booking flights and subscribing to services, your payments will always go
            through—effortlessly!
          </p>
          <ReadMore url={'/products/dollar-card'} />
        </div>
      </div>
    </div>
  );
}
