import React from 'react';
import { Link } from 'react-router-dom';
import { Target } from 'containers/Home';
import { POUCHERS_WEBSITE_URL } from 'constants';
import { ReactComponent as Phone } from 'assets/svg/phone.svg';
import { ReactComponent as TikTok } from 'assets/svg/tiktok.svg';
import { ReactComponent as Twitter } from 'assets/svg/twitter.svg';
import { ReactComponent as AppLogo } from 'assets/svg/app-logo.svg';
import { ReactComponent as AppStore } from 'assets/svg/app-store.svg';
import { ReactComponent as Facebook } from 'assets/svg/fb-facebook.svg';
import { ReactComponent as LinkedIn } from 'assets/svg/li-linkedin.svg';
import { ReactComponent as PlayStore } from 'assets/svg/play-store.svg';
import { ReactComponent as Instagram } from 'assets/svg/ig-instagram.svg';
import { ReactComponent as Mail } from 'assets/svg/material-symbols_mail.svg';

export default function Footer() {
  return (
    <footer>
      <Target />
      <div className="bg-[#1D2939] flex flex-col gap-16 py-16">
        <div className="container flex flex-col sm:flex-row justify-between">
          <div className="mb-[32px] sm:mb-0 flex flex-col gap-8 text-footer-text">
            <AppLogo className="" />
            <p className="max-w-[320px] text-16 font-dmsans_r leading-[24px]">
              Take charge with Pouchers—automate bills, track spending, and get virtual cards. It’s
              your all-in-one financial sidekick!
            </p>
            <div className="flex items-center gap-[5px]">
              <Mail />
              <a href="mailto:help@pouchers.io" className="font-dmsans_r font-normal text-16">
                help@pouchers.io
              </a>
            </div>
            <p className="font-dmsans_r font-normal text-16">Live Chat Support is available 24/7</p>
          </div>
          <div>
            <p className="mb-[16px] text-white text-16 font-dmsans_m">Get the app</p>
            <Link to={`${process.env.REACT_APP_POUCHERS_APP_IOS}`} target="_blank">
              <AppStore className="cursor-pointer mb-[16px] w-[135px]" />
            </Link>
            <Link to={`${process.env.REACT_APP_POUCHERS_APP_ANDROID}`} target="_blank">
              <PlayStore className="cursor-pointer w-[135px]" />
            </Link>
          </div>
        </div>
        <nav className="flex flex-col md:flex-row md:mx-auto w-fit pl-4 sm:pl-8 md:pl-0 text-white text-16 font-dmsans_m">
          <a href={`${POUCHERS_WEBSITE_URL}/blog`} className="mr-[32px] mb-[20px] sm:mb-0 hide_tap">
            Blog
          </a>
          <Link to="/terms-of-use" className="mr-[32px] mb-[20px] sm:mb-0 hide_tap">
            Terms of Use
          </Link>
          <Link to="/privacy-policy" className="cursor-pointer hide_tap">
            Privacy Policy
          </Link>
        </nav>
        <div className="container flex flex-col sm:flex-row justify-between items-center pt-[32px] border-t border-t-[#475467]">
          <p className="text-16 mb-[10px] sm:mb-0 font-dmsans_r font-normal text-[#98A2B3]">
            © {new Date().getFullYear()} Pouchers Inc. All rights reserved.
          </p>
          <ul className="flex gap-[24px]">
            <li>
              <Link to="https://x.com/mypoucher" target="_blank">
                <Twitter />
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/mypoucher" target="_blank">
                <Instagram />
              </Link>
            </li>
            <li>
              <Link to="https://linkedin.com/company/pouchers" target="_blank">
                <LinkedIn />
              </Link>
            </li>
            <li>
              <Link to="https://www.facebook.com/profile.php?id=100093554435916" target="_blank">
                <Facebook />
              </Link>
            </li>
            <li>
              <Link to="https://www.tiktok.com/@mypoucher" target="_blank">
                <TikTok />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
