import React from 'react';
import Flags from '../../assets/images/flags.svg';

const Location = () => {
  return (
    <div className="bg-[#0E0E40]">
      <div className="container flex flex-col md:flex-row md:items-center md:justify-between gap-[48px] md:gap-[80px] py-[70px] md:py-[150px]">
        <h3 className="text-[36px] leading-[44px] md:text-[42px] font-dmsans_b text-white w-3/4 md:w-1/2 max-w-[612px] lg:text-[48px] lg:leading-[57.6px]">
          From Lasgidi to Timbuktu, we’ve got you covered
        </h3>
        <div className="w-full md:w-1/2 max-w-[650px] relative">
          <img alt="flags" src={Flags} />
        </div>
      </div>
    </div>
  );
};

export default Location;
